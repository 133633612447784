import React from 'react';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';

function Project() {
  return (
    <>
      <Container maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Το έργο
        </Typography>
        <Container>
          <Typography
            variant="h6"
            align="justify"
            color="text.secondary"
            component="p"
            sx={{ pb: 3 }}
          >
            H SYMBIOLABS υλοποιεί το έργο SymbioICT “Τεχνολογίες αναλυτικής
            δεδομένων για την υποστήριξη αλυσίδων αξίας βιομάζας και αποβλήτων
            σε συμβιωτικά δίκτυα” στα πλαίσια της Ενιαίας Δράσης Κρατικών
            Ενισχύσεων Έρευνας Τεχνολογικής Ανάπτυξης και Καινοτομίας «ΕΡΕΥΝΩ –
            ΔΗΜΙΟΥΡΓΩ – ΚΑΙΝΟΤΟΜΩ», Παρέμβαση III: «Αξιοποίηση Ερευνητικών
            Αποτελεσμάτων».
          </Typography>

          <Typography
            variant="h6"
            align="justify"
            color="text.secondary"
            component="p"
          >
            Το έργο θα αξιοποιήσει ερευνητικά αποτελέσματα που διαθέτει η
            SYMBIOLABS για να αναπτύξει (α) μοντέλα δεδομένων αξιοποίησης
            βιομάζας και αποβλήτων που θα συνδέουν την πληροφορία για τα
            χαρακτηριστικά τους με τις εναλλακτικές επιλογές αξιοποίησής τους σε
            ένα συμβιωτικό δίκτυο, και (β) μεθόδους αναλυτικής δεδομένων για την
            αξιοποίηση βιομάζας και αποβλήτων. Τελικός στόχος είναι η υποστήριξη
            αποφάσεων ολοκληρωμένης αξιοποίησης βιομάζας και αποβλήτων και
            αναζήτησης πρώτων υλών για επιχειρήσεις.
          </Typography>
        </Container>
      </Container>
    </>
  );
}

export default Project;

import React from 'react';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';

function Company() {
  return (
    <>
      <Container maxWidth="lg" sx={{ pt: 8, pb: 6 }} component="main">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Η εταιρεία
        </Typography>
        <Container>
          <Typography
            variant="h6"
            align="justify"
            color="text.secondary"
            component="p"
            sx={{ pb: 3 }}
          >
            Η SYMBIOLABS ιδρύθηκε στην Αθήνα το 2019. Στόχος της εταιρείας είναι
            η ανάπτυξη και παροχή εξειδικευμένων υπηρεσιών και ψηφιακών
            εφαρμογών πάνω σε αλυσίδες αξίας δεδομένων (data value chains) για
            την προώθηση και την υποστήριξη της Κυκλικής Οικονομίας.
          </Typography>
          <Typography
            variant="h6"
            align="justify"
            color="text.secondary"
            component="p"
            sx={{ pb: 3 }}
          >
            H SYMBIOLABS υιοθετεί μια διεπιστημονική προσέγγιση για το σχεδιασμό
            λύσεων στις προκλήσεις για τη μετάβαση προς την Κυκλική Οικονομία,
            υλοποιώντας την Ευφυή Κυκλική Μετάβαση (Circular Intelligence).
            Αξιοποιώντας την υψηλή τεχνογνωσία μας, τόσο στον χώρο των
            τεχνολογιών Eπιχειρηματικής Eυφυΐας (Business Intelligence) και
            Mεγάλων Δεδομένων, Αναλυτικής Δεδομένων, Μηχανικής Μάθησης (Big Data
            Analytics, Machine Learning), όσο και στον χώρο των Βιομηχανικών
            Διεργασιών (Industrial Processes), σχεδιάζουμε και προσφέρουμε
            καινοτόμες λύσεις στα προβλήματα της αγοράς καθώς και υποστήριξη σε
            τοπικούς και εθνικούς φορείς χάραξης πολιτικής.
          </Typography>
          <Typography
            variant="h6"
            align="justify"
            color="text.secondary"
            component="p"
            sx={{ pb: 3 }}
          >
            H SYMBIOLABS υλοποιεί καινοτόμα ψηφιακά προϊόντα για την αναλυτική
            δεδομένων κατανάλωσης πόρων, την έξυπνη λήψη αποφάσεων διαχείρισης
            και αξιοποίησης αποβλήτων, την οργάνωση και υποστήριξη δράσεων
            βιομηχανικής συμβίωσης, τη βελτιστοποίηση των εφοδιαστικών αλυσίδων
            μεταφοράς αποβλήτων και παραπροϊόντων και τη μείωση του
            περιβαλλοντικού αποτυπώματος των βιομηχανικών δραστηριοτήτων.
          </Typography>

          <Typography
            variant="h6"
            align="justify"
            color="text.secondary"
            component="p"
          >
            H SYMBIOLABS είναι τεχνοβλαστός του Ερευνητικού Κέντρου “Αθηνά”. Το
            Ερευνητικό Κέντρο «Αθηνά» είναι το μοναδικό Ερευνητικό Κέντρο στη
            χώρα με αποκλειστικό προσανατολισμό τις τεχνολογίες και τις
            εφαρμογές της Πληροφορικής, των Επικοινωνιών και των Υπολογιστικών
            Επιστημών.
          </Typography>
        </Container>
        <Container maxWidth="xs">
          <img
            src={`${process.env.PUBLIC_URL}/Symbiolabs-Logo-large.png`}
            alt="ict logo"
          />
        </Container>
      </Container>
    </>
  );
}

export default Company;

import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';


function Home() {
  return (
    <>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }}
      />
      <Container maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          SYMBIOICT: Ψηφιακές υπηρεσίες για την Κυκλική Οικονομία
        </Typography>
        <Container sx={{ textAlign: 'justify' }}>
          <Typography gutterBottom component="p" variant="h6" color="text.secondary">
            Η δράση SYMBIOICT εφαρμόζει μια δεδομενοκεντρική προσέγγιση στην
            ανίχνευση σεναρίων αξιοποίησης βιομάζας και αποβλήτων. Για το σκοπό
            αυτό αναπτύσσουμε καινοτόμες μεθόδους εφαρμογής αναλυτικής δεδομένων
            για την αντιμετώπιση δύο βασικών τεχνολογικών προκλήσεων:
          </Typography>
          <Container>
            <Typography gutterBottom component="p" variant="h6" color="text.secondary">
              [α] την ανάπτυξη μεθόδων εντοπισμού αποβλήτων και βιομηχανικών
              διεργασιών, η σύνδεση των οποίων θα δημιουργήσει σενάρια
              αξιοποίησής τους,
            </Typography>
            <Typography gutterBottom component="p" variant="h6" color="text.secondary">
              [β] την ανάπτυξη μεθόδων οπτικής αναλυτικής για την απεικόνιση του
              συμβιωτικού δικτύου σε μια γεωγραφική περιοχή.
            </Typography>
          </Container>
          <Typography gutterBottom component="p" variant="h6" color="text.secondary">
            Με τις ανωτέρω τεχνολογίες θα προσφέρουμε ολοκληρωμένες υπηρεσίες
            λήψης αποφάσεων για την αξιοποίηση βιομάζας και αποβλήτων καθώς και
            για την αναζήτηση εναλλακτικών πρώτων υλών σε βιομηχανικές
            διεργασίες.
          </Typography>
        </Container>
      </Container>
    </>
  );
}

export default Home;


import Typography from '@mui/material/Typography';
import { Container, Link } from '@mui/material';

function Contact() {
  return (
    <Container maxWidth="lg" component="main" sx={{ pt: 8, pb: 6 }}>
      <Typography variant="h2" align="center" color="text.primary" gutterBottom>
        Στοιχεία επικοινωνίας
      </Typography>

      <Container>
        <Typography
          variant="h6"
          align="left"
          color="text.secondary"
          component="h5"
          sx={{ pb: 1 }}
        >
          Για περισσότερες πληροφορίες για το έργο SYMBIOICT και τα ψηφιακά
          εργαλεία επικοινωνήστε μαζί μας στο{' '}
          <Link href="mailto:feedback@symbiolabs.gr">
            feedback@symbiolabs.gr
          </Link>
        </Typography>
      </Container>
    </Container>
  );
}

export default Contact;

import React from 'react'
import { Typography, Container } from '@mui/material';


function NotFound() {
    return (
      <>
        
        <Container maxWidth="lg" sx={{ pt: 8, pb: 6 }} component="main">
        <img style={{margin: '0 auto', maxWidth:"min(500px, 100%)"}} src={`${process.env.PUBLIC_URL}/confusedtravolta.jpg`} alt="confused travolta"/>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            404
          </Typography>
          <Typography component="h2" variant="h2" align="center" color="text.secondary">
            Η σελίδα δεν βρέθηκε
          </Typography>
        </Container>
      </>
    );
}

export default NotFound;
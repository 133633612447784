import React from 'react';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';

function DigitalTools() {
  return (
    <>
      <Container maxWidth="lg" sx={{ pt: 8, pb: 6 }} component="main">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Ψηφιακά Εργαλεία
        </Typography>
        <Container>
          <Typography
            variant="h6"
            align="justify"
            color="text.secondary"
            component="p"
            sx={{ pb: 3 }}
          >
            Δοκιμάστε τα εργαλεία που αναπτύξαμε και δημιουργήστε λογαριασμό για
            να λαμβάνετε προσωποποιημένες υπηρεσίες
          </Typography>
          <ul>
            <li>
              <Typography
                variant="h6"
                align="justify"
                component="p"
                sx={{ pb: 3 }}
              >
                Βιομηχανική Συμβίωση - Ανίχνευση δυναμικού<br />
                <a href="https://apps.symbiolabs.gr">
                  (Δείτε ευκαιρίες δικτύωσης επιχειρήσεων και φορέων για να
                  ανταλλάξουν υλικά, ενέργεια, νερό και υποπροϊόντα)
                </a>
              </Typography>
            </li>
            <li>
              <Typography
                variant="h6"
                align="justify"
                component="p"
                sx={{ pb: 3 }}
              >
                Waste Analytics – Δεδομένα παραγωγής αποβλήτων στους Δήμους<br />
                <a href="https://apps.symbiolabs.gr">
                  (Δείτε ανάλυση των δεδομένων για τα παραγόμενα απόβλητα στους
                  δήμους της χώρας για τον ΕΚΑ που σας ενδιαφέρει.)
                </a>
              </Typography>
            </li>
            <li>
              <Typography
                variant="h6"
                align="justify"
                component="p"
                sx={{ pb: 3 }}
              >
                Waste Brokers – Εύρεση υπηρεσιών διαχείρισης αποβλήτων<br />
                <a href="https://apps.symbiolabs.gr">
                  (Δείτε όλες τις επιχειρήσεις παραλαβής και αποθήκευσης
                  αποβλήτων για τον ΕΚΑ που σας ενδιαφέρει.)
                </a>
              </Typography>
            </li>
            <li>
              <Typography
                variant="h6"
                align="justify"
                component="p"
                sx={{ pb: 3 }}
              >
                Πιλότικές εφαρμογες<br />
                <a href="https://apps.symbiolabs.gr">
                  (Δείτε εδώ τις πιλοτικές εφαρμογές.)
                </a>
              </Typography>
            </li>
          </ul>
        </Container>
      </Container>
    </>
  );
}

export default DigitalTools;

import { useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { NavLink } from 'react-router-dom';
import { Container, MenuList, MenuItem, Box, Menu } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const pages = [
  'Αρχική',
  'Το έργο',
  'Η εταιρία',
  'Ψηφιακά Εργαλεία',
  'Επικοινωνία',
];
const urls = [
  '/',
  '/the-project',
  '/company',
  '/digital-tools',
  '/contact',
];

function Header() {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
    >
      <Container>
        <Toolbar sx={{ flexWrap: 'no-wrap' }}>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              width: 1,
              pr: 1,
            }}
          >
            <MenuIcon
              aria-label="menu of the site"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            />
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                width: 1,
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, index) => {
                return (
                  <MenuItem key={index} sx={{ p: 0 }}>
                    {urls[index].startsWith('https:') ? (
                      <a href={urls[index]}>{page}</a>
                    ) : (
                      <NavLink to={urls[index]}>{page}</NavLink>
                    )}
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
          <img
            src={`${process.env.PUBLIC_URL}/SymbioICT_Logo.png`}
            alt="ict logo"
          />
          <nav>
            <MenuList sx={{ display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page, index) => {
                return (
                  <MenuItem key={index} sx={{ p: 0 }}>
                    {urls[index].startsWith('https:') ? (
                      <a href={urls[index]}>{page}</a>
                    ) : (
                      <NavLink to={urls[index]}>{page}</NavLink>
                    )}
                  </MenuItem>
                );
              })}
            </MenuList>
          </nav>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;

import React from 'react';
import {Routes, Route} from 'react-router-dom';
import './App.css';


import Home from './components/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Project from './components/Project';
import Company from './components/Company';
import NotFound from './components/NotFound';
import DigitalTools from './components/DigitalTools';

function App() {
  return (
    <>
    <Header />
    <Routes>

    <Route  path="/" element={<Home />}/>
    <Route  path="/the-project" element={<Project />}/>
    <Route  path="/company" element={<Company />}/>
    <Route path="/digital-tools" element={<DigitalTools />}/>
    <Route  path="/contact" element={<Contact />}/>
    <Route path="*" element={<NotFound />} />
    </Routes>
    <img className='espa-banner' src={`${process.env.PUBLIC_URL}/espa.png`} alt="ESPA logo"/>
    <Footer />
    </>
  );
}

export default App;
